.profile-photo {
  width: 160px;
  height: 160px;
  border-radius: 90px;
}

.container {
  margin: 5px;
  border-radius: 15px;
}

.user-profile {
  width: 70vw;
}

@media screen and (max-width: 600px) {
  .user-edit {
    width: 100px;
  }
}

@media screen and (max-width: 600px) {
  .user-profile {
    width: 80vw;
  }
}

.profile-button {
  border-radius: 90px;
  border: none;
}

.chat-bubble {
  width: 130px;
  margin-left: -65px;
}

.profile-text {
  color: rgb(70, 70, 70);
}
