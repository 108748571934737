.activity-btn {
  background-color: #1d3557; /* Green */
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 25px;
  padding: 0.8rem;
  margin: 1rem;
  border-radius: 15px;
  border: 2px solid #a8dadc;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card {
  border-radius: 15px;
  align-items: center;
}

.detail-map {
  height: 50vh;
  width: 50vw;
}

input {
  display: flex;
  align-items: center;
  flex-direction: column;
}

form button {
  background-color: #1d3557; /* Green */
  color: white;
  padding: 10px 22px;
  font-size: 15px;
  margin: 1rem;
  border-radius: 15px;
  border: 2px solid #a8dadc;
}

.activity-list {
  width: 90vw;
}

.form {
  width: 80vw;
  height: 50vh;
}

.profile-photo-details {
  width: 60px;
  height: 60px;
  border-radius: 90px;
}

.back-img {
  background-image: ("../assets/sunshine.gif");
}

.activity-profile {
  margin-bottom: 20px;
}

.button-container {
  display: flex;
  margin: 5px;
}

.edit-form {
  width: 80vw;
}

.edit-child {
  flex-direction: column;
}

.activity-text {
  color: rgb(70, 70, 70);
}
