* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  color: #4f5661;
  font-size: large;
}

@import "add-to-calendar-button/assets/css/atcb.min";
