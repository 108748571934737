.header {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif,
    sans-serif;
  margin-top: 30px;
  margin-bottom: -10px;
  color: rgb(70, 70, 70);
  font-size: 40px;
  width: 100vw;
  display: block;
  text-align: center;
  font-weight: bolder;
}

@media screen and (max-width: 600px) {
  .header {
    font-size: 32px;
  }
  .sub-header {
    font-size: 9px;
  }
}

.sub-header {
  font-size: 20px;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-style: italic;
}

.header-container {
  margin-bottom: 50px;
}

.loading-component {
  align-items: center;
}
