body {
  background: rgb(168, 218, 220);
  background: linear-gradient(
    90deg,
    rgba(168, 218, 220, 1) 53%,
    rgba(241, 250, 238, 1) 100%
  );
}

.login {
  background-image: url(./login.gif);
  background-size: 130%;
  background-repeat: no-repeat;
  background-position: 50%;
  padding-top: -30px;
  width: 50vw;
  height: 450px;
  border: 2px solid black;
}
.login button {
  padding: 0.5rem;
  margin: 1rem;
  border-radius: 15px;
  background-color: #f1faee;
}

.login a {
  padding: 0.5rem;
  border-radius: 15px;
  background-color: #f1faee;
}

.login input {
  margin: 10px;
}

@media screen and (max-width: 600px) {
  .login {
    background-image: url(./berlin.gif);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position-y: 0px;

    padding: 0px;
    width: 80vw;
    height: 50vh;
    border: 2px solid black;
  }
  .login button {
    padding: 0.5rem;
    margin: 20px;
    border-radius: 15px;
  }

  .login input {
    position: relative;
  }
  .parent {
    width: 80vw;
  }
}

.story {
  width: 500px;
}
@media screen and (max-width: 600px) {
  .story {
    width: 70vw;
  }
}

.profile-photo-selection {
  width: 100px;
  height: 100px;
  border-radius: 90px;
}
.profile-photo-selection:hover {
  border: 3px solid #4fd65d;
  transform: scale(110%);
  transition: 0.5s linear;
}

.sign-up {
  width: 35vw;
}

@media screen and (max-width: 600px) {
  .sign-up {
    width: 80vw;
  }
}

.home-text {
  color: rgb(70, 70, 70);
}

.parent {
  justify-content: center;
}
